import EmblaCarousel, { EmblaOptionsType, EmblaCarouselType } from '../../../../../embla-carousel/packages/embla-carousel'
// import EmblaCarousel, {EmblaOptionsType, EmblaCarouselType} from 'embla-carousel'
// import '../css/base.css'
// import '../css/sandbox.css'
// import '../css/embla.css'

enum ViewModes {
	small,
	middle,
	big
}

const getViewMode = (): number => {
	let mode: number
	// This is the width without the scrollbar, but css media screen estimates it with the scrollbar.
	// So use window.innerWidth
	// let viewWidth = document.documentElement.clientWidth
	let viewWidth = window.innerWidth

	if (viewWidth > 850) {
		mode = ViewModes.big
	} else if (viewWidth > 550) {
		mode = ViewModes.middle
	} else {
		mode = ViewModes.small
	}
	return mode
}

export const addPrevNextBtnsClickHandlers = (
	emblaApi: EmblaCarouselType,
	prevBtn: HTMLElement,
	nextBtn: HTMLElement,
): (() => void) => {
	const scrollPrev = () => emblaApi.scrollPrev()
	const scrollNext = () => emblaApi.scrollNext()
	prevBtn.addEventListener('click', scrollPrev, false)
	nextBtn.addEventListener('click', scrollNext, false)

	return (): void => {
		prevBtn.removeEventListener('click', scrollPrev, false)
		nextBtn.removeEventListener('click', scrollNext, false)
	}
}

export const addTogglePrevNextBtnsActive = (
	emblaApi: EmblaCarouselType,
	prevBtn: HTMLElement,
	nextBtn: HTMLElement,
): (() => void) => {
	const togglePrevNextBtnsState = (): void => {
		if (emblaApi.canScrollPrev()) prevBtn.removeAttribute('disabled')
		else prevBtn.setAttribute('disabled', 'disabled')

		if (emblaApi.canScrollNext()) nextBtn.removeAttribute('disabled')
		else nextBtn.setAttribute('disabled', 'disabled')
	}

	emblaApi
		.on('select', togglePrevNextBtnsState)
		.on('init', togglePrevNextBtnsState)
		.on('reInit', togglePrevNextBtnsState)

	return (): void => {
		prevBtn.removeAttribute('disabled')
		nextBtn.setAttribute('disabled', 'disabled')
	}
}

export const addDotBtnsAndClickHandlers = (
	emblaApi: EmblaCarouselType,
	dotsNode: HTMLElement,
): (() => void) => {
	let dotNodes: HTMLElement[] = []

	const addDotBtnsWithClickHandlers = (): void => {
		dotsNode.innerHTML = emblaApi
			.scrollSnapList()
			.map(() => '<button class="embla__dot" type="button"></button>')
			.join('')

		dotNodes = Array.from(dotsNode.querySelectorAll('.embla__dot'))
		dotNodes.forEach((dotNode, index) => {
			dotNode.addEventListener('click', () => emblaApi.scrollTo(index), false)
		})
	}

	const toggleDotBtnsActive = (): void => {
		const previous = emblaApi.previousScrollSnap()
		const selected = emblaApi.selectedScrollSnap()
		dotNodes[previous].classList.remove('embla__dot--selected')
		dotNodes[selected].classList.add('embla__dot--selected')
	}

	emblaApi
		.on('init', addDotBtnsWithClickHandlers)
		.on('reInit', addDotBtnsWithClickHandlers)
		.on('init', toggleDotBtnsActive)
		.on('reInit', toggleDotBtnsActive)
		.on('select', toggleDotBtnsActive)

	return (): void => {
		dotsNode.innerHTML = ''
	}
}

let TWEEN_FACTOR = 1.25
let numSlides: number;

// Make the selected item wider and show texts next to the images with this number of pixels
const numberWithinRange = (number: number, min: number, max: number): number =>
	Math.min(Math.max(number, min), max)

/**
 * Returns array of numbers. Example: -1, -0.75, -0,5, -0,25, 0, 0,25...1, where 0 is the target
 * @param emblaApi
 */
const getDiffsToTarget = (emblaApi: EmblaCarouselType): number[] => {
	const engine = emblaApi.internalEngine()
	const scrollProgress = emblaApi.scrollProgress()

	return emblaApi.scrollSnapList().map((scrollSnap: number, index: number) => {
		let diffToTarget = scrollSnap - scrollProgress

		if (engine.options.loop) {
			engine.slideLooper.loopPoints.forEach((loopItem) => {
				const target = loopItem.target().get()
				if (index === loopItem.index && target !== 0) {
					const sign = Math.sign(target)
					if (sign === -1) diffToTarget = scrollSnap - (1 + scrollProgress)
					if (sign === 1) diffToTarget = scrollSnap + (1 - scrollProgress)
				}
			})
		}
		return diffToTarget
	})
}

export const setupTweenOpacity = (
	emblaApi: EmblaCarouselType,
): {
	applyTweenOpacity: () => void
	removeTweenOpacity: () => void
} => {
	const tweenNodes = emblaApi.slideNodes()

	const applyTweenOpacity = (): void => {
		const diffsToTarget = getDiffsToTarget(emblaApi)
		let translateX: number
		diffsToTarget.forEach((diffToTarget, index) => {
			let tweenValue: number
			const selectedIndex = emblaApi.selectedScrollSnap()
			
			// if (getViewMode() !== ViewModes.big) {
				tweenValue = numberWithinRange((1 - Math.abs(diffToTarget * TWEEN_FACTOR)), 0, 1)
			// } else {
			// 	tweenValue = numberWithinRange((1 - Math.abs(diffToTarget * TWEEN_FACTOR)), 0, 1)
			// 	if (index === selectedIndex) {
			// 		tweenValue = 1
			// 	} else	{
			// 		tweenValue = 0.9
			// 	}
			// }

			const elementInnerItems = <HTMLElement>(tweenNodes[index].querySelector('.embla__slide__items'));
			if (elementInnerItems !== null) {
				elementInnerItems.style.opacity = tweenValue.toString()
			}
			
			const linkTop = <HTMLAnchorElement>(tweenNodes[index].querySelector('.embla__button__top'));

			let scale: number
			// if (getViewMode() !== ViewModes.big) {
				scale = (1 - (Math.abs(diffToTarget) / 3))
			// } else {
			// 	scale = (1 - (Math.abs(diffToTarget) / 10))
			// }
			
			if (linkTop) {
				linkTop.style.transform = 'scale(' + scale.toString() + ')'
				linkTop.style.opacity = (tweenValue * 3).toString()
			}

			if (index === selectedIndex) {
				if (linkTop !== null) {
					linkTop.classList.add('embla__button__top--active')
				}
				// makeItemLarge(tweenNodes[index], diffToTarget * 100)
				tweenNodes[index].classList.add('embla__slide--selected')
				tweenNodes[index].style.zIndex = Math.ceil(diffToTarget * 100).toString()
				translateX = 0
			} else if (index < selectedIndex) {
				if (linkTop !== null) {
					linkTop.classList.remove('embla__button__top--active')
				}

				// if (getViewMode() !== ViewModes.big) {
					translateX = -(100 * diffToTarget)
				// } else {
				// 	translateX = 0
				// }
				
				// makeItemSmall(tweenNodes[index], diffToTarget * 100)
				tweenNodes[index].classList.remove('embla__slide--selected')
				tweenNodes[index].style.zIndex = Math.ceil(diffToTarget * 100).toString()
			} else {
				if (linkTop !== null) {
					linkTop.classList.remove('embla__button__top--active')
				}
				// if (getViewMode() !== ViewModes.big) {
					translateX = -(100 * diffToTarget)
				// } else {
				// 	translateX = 0
				// }
				tweenNodes[index].classList.remove('embla__slide--selected')
				tweenNodes[index].style.zIndex = Math.ceil((-diffToTarget * 100)).toString()
			}
			
			const elementClipper = <HTMLElement>tweenNodes[index].querySelector('.embla__slide__items__clipper')
			
			tweenNodes[index].style.transform = 'translateX(calc(' + translateX + '% - ((var(--slide-size-selected) - var(--slide-size)) / 2)))'

			let moveDown: number
			// if (getViewMode() !== ViewModes.big) {
				moveDown = Math.round(((Math.abs(diffToTarget))) * 5)
			// } else {
			// 	moveDown = 0
			// }
			elementInnerItems.style.transform = 'translateY(' + moveDown.toString() + '%) scale(' + scale.toString() + ')'
			// elementInnerItems.style.transform = 'scale(' + scale + ')'
			
			// diffTarget 0 -+ 0.2
			const widenProgress = (1 - (Math.abs(diffToTarget) * numSlides))
			if (widenProgress > 0 && widenProgress <= 1) {
				// tweenNodes[index].style.maxWidth = 'calc(var(--slide-size-selected) + var(--slide-padding))'
				tweenNodes[index].style.maxWidth = 'calc(var(--slide-size) + var(--slide-padding) + (var(--slide-size-selected) - var(--slide-size)) * ' + widenProgress.toString() + ')'
				tweenNodes[index].style.minWidth = 'calc(var(--slide-size) + var(--slide-padding) + (var(--slide-size-selected) - var(--slide-size)) * ' + widenProgress.toString() + ')'
				if (elementClipper !== null) {
					elementClipper.style.maxWidth = 'calc(var(--slide-size) + (var(--slide-size-selected) - var(--slide-size)) * ' + widenProgress.toString() + ')'
					elementClipper.style.minWidth = 'calc(var(--slide-size) + (var(--slide-size-selected) - var(--slide-size)) * ' + widenProgress.toString() + ')'
				}
			} else {
				tweenNodes[index].style.maxWidth = 'calc(var(--slide-size) + var(--slide-padding))'
				tweenNodes[index].style.minWidth = 'calc(var(--slide-size) + var(--slide-padding))'
				if (elementClipper !== null) {
					elementClipper.style.maxWidth = 'calc(var(--slide-size))'
					elementClipper.style.minWidth = 'calc(var(--slide-size))'
				}
			}
		})
	}

	const removeTweenOpacity = (): void => {
		const tweenNodes = emblaApi.slideNodes()
		tweenNodes.forEach((slide: HTMLElement) => {
				slide.removeAttribute('style')

				const elementClipper = <HTMLElement>slide.querySelector('.embla__slide__items__clipper')
				const elementInnerItems = <HTMLElement>(slide.querySelector('.embla__slide__items'));
				if (elementInnerItems !== null) {
					elementInnerItems.removeAttribute('style')
				}
	
				const linkTop = <HTMLAnchorElement>(slide.querySelector('.embla__button__top'));
				linkTop.removeAttribute('style')

				slide.removeAttribute('style')
				slide.removeAttribute('style')
				if (elementClipper !== null) {
					elementClipper.removeAttribute('style')
					elementClipper.removeAttribute('style')
				}
			}
		)
	}

	return {
		applyTweenOpacity,
		removeTweenOpacity,
	}
}

const estimateOptions = (): EmblaOptionsType => {
	let options: EmblaOptionsType
// Will not loop if there is not enough content - does not loop on desktop
	if (getViewMode() === ViewModes.small) {
		options = {loop: false, startIndex: 2, inViewThreshold: 1}
	} else {
		options = {loop: false, startIndex: 2, inViewThreshold: 5}
	}
	// Could not make it work - throws error.
	// options = {
	// 	loop: false, startIndex: 2, inViewThreshold: 5,
	// 	breakpoints: {
	// 		// '(min-width: 768px)': {loop: false, startIndex: 2, inViewThreshold: 5},
	// 		'(max-width: 425px)': {loop: true}
	// 	}
	// }
	return options
}


const emblaNode = <HTMLElement>document.querySelector('.embla')
const viewportNode = <HTMLElement>emblaNode.querySelector('.embla__viewport')
const prevBtn = <HTMLElement>emblaNode.querySelector('.embla__button--prev')
const nextBtn = <HTMLElement>emblaNode.querySelector('.embla__button--next')
const dotsNode = <HTMLElement>document.querySelector('.embla__dots')

export let emblaApi = EmblaCarousel(viewportNode, estimateOptions())

const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
	emblaApi,
	prevBtn,
	nextBtn,
)
const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
	emblaApi,
	prevBtn,
	nextBtn,
)
const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
	emblaApi,
	dotsNode,
)

const init = function() {
	const tweenNodes = emblaApi.slideNodes()
	numSlides = tweenNodes.length
	const selectedIndex = emblaApi.selectedScrollSnap()

	const elementClipper = <HTMLElement>tweenNodes[selectedIndex].querySelector('.embla__slide__items__clipper')

	tweenNodes[selectedIndex].style.maxWidth = 'calc(var(--slide-size) + var(--slide-padding) + (var(--slide-size-selected) - var(--slide-size)))'
	tweenNodes[selectedIndex].style.minWidth = 'calc(var(--slide-size) + var(--slide-padding) + (var(--slide-size-selected) - var(--slide-size)))'
	if (elementClipper !== null) {
		elementClipper.style.maxWidth = 'calc(var(--slide-size) + (var(--slide-size-selected) - var(--slide-size)))'
		elementClipper.style.minWidth = 'calc(var(--slide-size) + (var(--slide-size-selected) - var(--slide-size)))'
	}

	applyTweenOpacity()
}

const {applyTweenOpacity, removeTweenOpacity} = setupTweenOpacity(emblaApi)

emblaApi
	.on('destroy', removePrevNextBtnsClickHandlers)
	.on('destroy', removeTogglePrevNextBtnsActive)
	.on('destroy', removeDotBtnsAndClickHandlers)
	.on('init', init)
	.on('scroll', applyTweenOpacity)
	.on('reInit', init)
	.on('destroy', removeTweenOpacity)
	.on('beforeActivate', removeTweenOpacity)
